/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";



.flex-center {
    display : flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

ion-split-pane{ 
    --border: none !important;
    ion-menu{
     --background: var(--ion-color-tertiary) !important; 
        ion-content{            
            --background: #901aff !important; 
           /*  ion-list-header{ 
                --background: var(--ion-color-tertiary) !important;
                --color: #FFFFFF !important;   
            } */
            ion-item{
                --background: var(--ion-color-tertiary) !important;
                 --color: #FFFFFF !important;
                 ion-icon{ padding-right: 10px !important; }
            }
        }
    }
}

/* ion-header {
    ion-toolbar{
        --background: var(--ion-color-tertiary)
        --color: #FFFFFF !important;
    }
} */

ion-modal {
    &.center {
      --width: calc(100% - 2em);
      align-items: center;
      --height: auto;
      .ion-page {
        position: relative;
        display: block;
        contain: content;
      }
    }
  }